import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import background from '../../static/background.png';

import Footer from './Footer';
import Featured from './Featured';
import HomeNews from './HomeNews';

function Layout({ children }) {
	return (
		<div className="font-sans text-gray-900">
			<Div>
				<HomeNews />
				<Featured />
				{/* <main className="flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16">{children}</main> */}
				<main className="w-full">{children}</main>
			</Div>
			<Footer />
		</div>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;

const Div = styled.div`background-image: url(${background});`;
