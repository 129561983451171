import React, { useState } from 'react';
import logo from '../images/logo.svg';
import { FaAlignJustify, FaTimes, FaFacebook, FaYoutube } from 'react-icons/fa';
import { Link } from 'gatsby';
import Headroom from 'react-headroom';
import styled from 'styled-components';

const Navbar = () => {
	const [ isOpen, setisOpen ] = useState(false);
	const handleClick = () => {
		setisOpen(!isOpen);
		document.getElementById('buttonNav').classList.toggle('mytransition');
	};

	const activeStyle = {
		color: '#528B8A'
	};
	return (
		<Div className="container">
			<Headroom >
				<nav
					className={`lg:flex lg:justify-between lg:items-center align-middle ${isOpen
						? 'pb-0 pt-6'
						: 'py-4 '}`}
				>
					<div className="flex items-center justify-between px-4 md:px-0 ">
						<div className="logo">
							<Link to="/">
								<img src={logo} alt="logo eglise evangélique baptiste Rouen" className="h-6 md:h-10 mb-0" />
							</Link>
						</div>
						<div>
							<button
								onClick={handleClick}
								type="button"
								className="block text-gray-500 hover:text-blue-500 focus:outline-none lg:hidden"
								id="buttonNav"
							>
								{!isOpen ? (
									<div className=" text-2xl text-eeb-green-dark">
										<FaAlignJustify />
									</div>
								) : (
									<div className="text-3xl  text-eeb-green-dark">
										<FaTimes />
									</div>
								)}
							</button>
						</div>
					</div>
					<div
						className={`list-item py-2 lg:flex ${isOpen
							? 'block'
							: 'hidden'} mt-4 sm:mt-0  px-0 font-bold text-gray-700`}
					>
						<Link
							to="/"
							className="block text-center  rounded px-4 py-2 mt-1 sm:mt-0 sm:ml-2  text-white mx-20 sm:mx-0"
							activeStyle={activeStyle}
						>
							Accueil
						</Link>
						<Link
							to="/#about"
							className="block text-center  rounded px-4 py-2 mt-1 sm:mt-0 sm:ml-2"
							activeStyle={activeStyle}
							id="#about"
						>
							A propos
						</Link>
						<Link
							to="/#contact"
							className="block text-center  rounded px-4 py-2 mt-1 sm:mt-0 sm:ml-2"
							activeStyle={activeStyle}
						>
							Contact
						</Link>
						<a
							href="https://www.facebook.com/EgliseEvangeliqueBaptisteRouen"
							target="_blank"
							rel="noreferrer"
							className=" text-center flex justify-center items-center  rounded px-4 py-2 mt-1 sm:mt-0 sm:ml-2 text-eeb-green-dark text-2xl"
						>
							<FaFacebook />
						</a>
						<a
							href="https://www.youtube.com/channel/UCPgc1EUedcd1I1ON9TapM8w/videos"
							target="_blank"
							rel="noreferrer"
							className=" text-center flex justify-center items-center  rounded px-4 py-2 mt-1 sm:mt-0 sm:ml-2 text-eeb-green-dark text-2xl"
						>
							<FaYoutube />
						</a>
					</div>
				</nav>
			</Headroom>
		</Div>
	);
};

export default Navbar;
const Div = styled.div`
	.headroom--scrolled {
		transition: transform 200ms ease-in-out;
		background-color: #f7fafc;

		/* @media (min-width: 640px) {
			padding: 0 38px;
		}
		@media (min-width: 1024px) {
			padding: 0 76px;
		} */
	}
`;
