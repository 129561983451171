import React from 'react';

const HomeNews = () => {
	return (
		<div className="py-2 text-center bg-eeb-red text-gray-100 shadow-2xl">
			<div className="container ">
				{/* <h2 className="text-2xl underline uppercase pb-2">En octobre</h2> */}
				<div className="text-sm sm:text-lg font-blackd">
					<p>Notre assemblée est ouverte. <span className="sm:hidden"><br/></span> Culte dimanche à 10h30</p>
					{/* <p>École du dimanche le dimanche 11</p>
					<p>Sainte Cène le dimanche 25</p> */}
				</div>
			</div>
		</div>
	);
};

export default HomeNews;