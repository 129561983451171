import React from 'react';
import logo from '../images/logo.svg';
import cnef from '../images/cnef.jpg';
import feebf from '../images/feeb.png';
import { SiMinutemailer } from 'react-icons/si';
import { GrLocation } from 'react-icons/gr';
import { FaFacebook, FaYoutube } from 'react-icons/fa';

const Footer = () => {
	return (
		<footer className="pt-24 pb-24 " id="contact">
			<div className="px-2 flex flex-col md:flex-row justify-center items-center ">
				<div>
					<img
						src={logo}
						alt="église évangélique baptiste Rouen eeb"
						width="180"
						className="mb-0 md: md:mr-20"
					/>
					<div className="flex flex-row pt-3">
						<a
							href="https://www.facebook.com/EgliseEvangeliqueBaptisteRouen"
							target="_blank"
							rel="noreferrer"
							className=" text-center flex justify-center items-center  rounded px-4 py-2 mt-1 sm:mt-0 sm:ml-2 text-eeb-green-dark text-2xl"
						>
							<FaFacebook />
						</a>
						<a
							href="https://www.youtube.com/channel/UCPgc1EUedcd1I1ON9TapM8w/videos"
							target="_blank"
							rel="noreferrer"
							className=" text-center flex justify-center items-center  rounded px-4 py-2 mt-1 sm:mt-0 sm:ml-2 text-eeb-green-dark text-2xl"
						>
							<FaYoutube />
						</a>
					</div>
				</div>
				<div className=" ">
					<div className="text-sm pt-4 text-eeb-green-dark">
						<p className="text-gray-600 pb-4 text-center md:text-left">Contact</p>
						<ul className="font-light">
							<li className="flex items-center pr-3">
								<div className="pr-3">
									<SiMinutemailer />
								</div>
								<a href="mailto: eebrouen@hotmail.com" target="_blank" rel="noreferrer">
									eebrouen@hotmail.fr
								</a>
							</li>
							<li className="flex justify-center items-center">
								<div className="pr-3">
									<GrLocation />
								</div>
								<a href="https://goo.gl/maps/vEykjzoDgZ2HyUtp6" target="_blank" rel="noreferrer">
									50 rue Albert Camus 76100 ROUEN
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="divide-y-4 divide-gray-800" />
			<hr />
			<div className="px-2 my-8 container  flex flex-col md:flex-row justify-center items-center pt-8 border-t-2 border-gray-300">
				<div className='mb-6 md:mb-0'>
					<img
						src={cnef}
						alt="église évangélique baptiste Rouen eeb et la cnef"
						width="60"
						className="mb-0 md: md:mr-20"
					/>
				</div>
				<div>
					<img
						src={feebf}
						alt="église évangélique baptiste Rouen eeb et la feebf"
						width="190"
						className="mb-0 md: md:mr-20"
					/>
				</div>
			</div>
			
			<p className="text-xs text-gray-500 text-center pt-8"> © Tous droits réservés {new Date().getFullYear()}</p>
		</footer>
	);
};

export default Footer;
