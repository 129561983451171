import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Navbar from './Navbar';

const Featured = () => {
	const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "header.png" }) {
				childImageSharp {
					fluid(maxWidth: 900, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`);
	const image = data.placeholderImage.childImageSharp.fluid;
	return (
		<div className="lg:pb-4 md:py-2">
			<Navbar />
			<div className="py-2 lg:pb-4 lg:pt-10 container">
				<div className="flex flex-wrap-reverse lg:flex-no-wrap justify-center items-center md:py-6">
					<div className="text-center lg:text-left pt-2 md:pt-0">
						<h2 className="text-3xl lg:text-4xl text-eeb-green-dark pt-5 pb-3">
							Eglise Evangélique Baptiste de Rouen{' '}
						</h2>
						<p className="pt-2 pb-6 italic text-eeb-gray md:pt-4 md:text-lg">
							Une église familiale, éclairante, pertinente
						</p>
						<Link to="/#about">
							<button className="bg-eeb-green-dark btn p-4 px- py- md:px-8 text-sm rounded text-gray-100 md:mt-2">
								En savoir plus
							</button>
						</Link>
					</div>
					<div className="px-1 pb-6 md:pb-0 md:px-0 pt-8 md:pt-0" style={{ width: 1200 }}>
						<Img
							fluid={image}
							alt="eglise évangélique baptiste de Rouen eeb"
							title="eeb eglise évangélique baptiste de Rouen"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Featured;
